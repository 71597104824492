@import "scss/index";
.tabsContainer {
  overflow-x: auto;
  @include no-scrollbar;
}

.tabs {
  display: inline-flex;
  width: 100%;
  //border-bottom: 1px solid $color-white-70;
}


//@include media-mobile {
//
//  .tabsContainer {
//    padding: 0 16px;
//  }
//}
