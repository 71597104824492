@import "scss/index";
.tab {
  margin-left: 20px;
  padding-bottom: 8px;
  color: $color-titanic;
  opacity: 0.72;
  white-space: nowrap;
  border-bottom: 4px solid transparent;
  border-radius: 2px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    opacity: 1;

    .counter {
      opacity: 1;
    }
  }

  &.active {
    cursor: default;
    opacity: 1;

    &.border-rocky {
      border-color: $color-rocky;
    }

    &.border-fargo {
      border-color: $color-fargo;
    }

    .counter {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.32;
    cursor: default;
  }
}

.counter {
  opacity: 0.72;
}