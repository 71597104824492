@import "scss/index";
.item {

  &:hover {

    .text {
      opacity: 1;
    }
  }
}

.text {
  color: $color-titanic;
  opacity: 0.72;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

.hover {

  &:hover {
    background-color: $color-rush;
  }
}

.leftBorder {

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: $color-white;
    content: '';
  }

  &:hover,
  &:global(.active) {

    &::before {
      background: $color-rocky;
    }
  }
}