@import "scss/index";
.menu {
  position: absolute;
  z-index: $layer-modal + 1;
  max-height: 480px;
  overflow: auto;
}

.left {
  top: 0;
  right: calc(100% + 4px);
}

.right {
  top: 0;
  left: calc(100% + 4px);
}

.bottomRight {
  top: calc(100% + 4px);
  right: 0;
}

.bottomLeft {
  top: calc(100% + 4px);
  left: 0;
}

@include media-mobile {

  .menu {
    max-width: calc(100vw - 48px);
  }
}
